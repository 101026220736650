<template>
    <div class="footer-container">
        <div class="container py-5">
            <div class="row footer-component">
                <div class="col-12 col-lg-4 mb-4">
                    <img src="../../assets/images/logo.png" class="footer-logo mb-2" alt="#">
                    <p class="mb-2" style="text-align: justify;">Medico: Your medicines delivered @home. On time, every time. Founded by professionals in the pharmaceutical industry with over 25 years of
                        experience, Medico is your one-stop solution for all your medicine needs. Call us for same day delivery and attractive discounts! </p>
                    <ul class="social-icons">
                        <li><a href="https://www.facebook.com/Medico-Pharma-104592148443285" target="_blank"><img
                            src="../../assets/images/social/1.png" alt="#"></a></li>
                        <li><a href="https://www.instagram.com/medico.tcr/" target="_blank"><img
                            src="../../assets/images/social/2.png" alt="#"></a></li>
                        <!--                        <li><a href="#" target="_blank"><img src="../../assets/images/social/3.png" alt="#"></a></li>-->
                    </ul>
                </div>
                <div class="col-12 col-lg-8">
                    <div class="row">
                        <div class="col-12 col-lg-4 mb-4">
                            <h6>Contact Us</h6>
                            <ul>
                                <li>
                                    29/702-F, Kalyanram Residency, Shornur Road, (Opp. Oushadhi Retail Outlet), Thrissur
                                    - 680 022
                                </li>
                                <li>
                                    <a href="tel:+91 7557556040">+91 755 755 60 40</a>
                                </li>
                                <li>
                                    <a href="mailto:medicopharma.tcr@gmail.com" target="_blank">
                                        medicopharma.tcr@gmail.com</a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-6 col-lg-4">
                            <h6>Links</h6>
                            <ul>
                                <li>
                                    <router-link to="/">
                                        Home
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/about">
                                        About Us
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/contactus/">
                                        Contact Us
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        <div class="col-6 col-lg-4">
                            <h6>Support</h6>
                            <ul>
                                <li class="d-none">
                                    <a href="#">
                                        Disclaimer
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://medico.xeoscript.com/Medico-Pharma-Privacy-Policy.pdf">
                                        Privacy Policy
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://medico.xeoscript.com/Medico-Pharma-TermsConditions.pdf">
                                        Terms & Conditions
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>

        </div>
        <div class="container-fluid">
            <div class="row footer-component1 text-center ">
                <div class="col">
                    <p>&#169; {{ new Date().getFullYear() }} Medico Pharmacy. All Rights Reserved <span
                        class="d-none d-md-inline-block">|</span> Designed by <a
                        href="https://xeoscript.com/" target="_blank"> Xeoscript Technologies</a></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    name: 'Footer',
    data () {
        return {};
    }
};
</script>

<style scoped>

.footer-logo {
    width: 160px;
}

.footer-container {
    background-color: #f4f4f4;
    position: relative;
}

.footer-component1 {
    padding: 1em 0 0 0em;
    /*color: rgb(184, 184, 184);*/
    font-size: 13px;
    border-top: 1px solid rgb(184, 184, 184);
}

.footer-component ul {
    padding: 0;
    list-style-type: none;
}

.footer-component ul li {
    padding-top: 0.4rem;
}

.footer-component h6 {
    font-weight: 600;
}

.social-icons li {
    display: inline-block;
    margin-right: 13px;
}

.social-icons li img {
    width: 22px;
}

@media (min-width: 992px) {
    .footer-logo {
        width: 180px;
    }
}
</style>
