<template>
    <header class="main-header-container" id="header" v-bind:class="navClass()">
        <div class="main-header container fl-te-c">
            <input id="toggle" type="checkbox">
            <div class="logo">
                <router-link to="/" class="fl-x-c">
                    <img src="@/assets/images/logo.png" class="logo-img" alt="logo">
                </router-link>

                <label for="toggle" class="hamburger" @click="close">
                    <div class="top-bun"></div>
                    <div class="meat"></div>
                    <div class="bottom-bun"></div>
                </label>
            </div>

            <div class="nav-list">
                <ul class="d-flex proxima-semi-bold">
                    <li @click="clicked">
                        <router-link to="/" class="but-container">
                            <button class="c-btn-b">HOME</button>
                            <div class="but-shadow"></div>
                            <div class="but-shadow-inset"></div>
                        </router-link>
                    </li>
                    <li @click="clicked">
                        <router-link to="/about/" class="but-container">
                            <button class="c-btn-b">ABOUT</button>
                            <div class="but-shadow"></div>
                            <div class="but-shadow-inset"></div>
                        </router-link>
                    </li>
                    <li @click="clicked">
                        <router-link to="/contactus/" class="but-container">
                            <button class="c-btn-b">CONTACT US</button>
                            <div class="but-shadow"></div>
                            <div class="but-shadow-inset"></div>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: 'MainHeader',
    props: {},
    data () {
        return {
            scrollPosition: null
        };
    },
    methods: {
        updateScroll () {
            this.scrollPosition = window.scrollY;
        },

        clicked () {
            const element = document.getElementById('toggle');
            const element1 = document.getElementById('toggleDrop');
            element.checked = false;
            if (element1.checked) {
                element1.checked = false;
            }
        },
        close () {
            const element1 = document.getElementById('toggleDrop');
            element1.checked = false;
        },

        navClass () {
            const element3 = document.getElementById('header');
            if (this.scrollPosition > 100) {
                if (element3) {
                    element3.classList.add('navbar-c');
                }
            } else {
                if (element3) {
                    element3.classList.remove('navbar-c');
                }
            }
        }
    },
    mounted () {
        window.addEventListener('scroll', this.updateScroll);
    },
    destroy () {
        window.removeEventListener('scroll', this.updateScroll);
    }
};
</script>

<style scoped lang="scss">
@-webkit-keyframes fadeIn1 {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeIn1 {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.router-link-exact-active {
    color: var(--color-primary);
}

.main-header-container {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
    transition: 0.6s;
}

.navbar-c.main-header-container {
    backdrop-filter: saturate(180%) blur(20px);
    background-color: rgba(255, 255, 255, 0.72);
    box-shadow: #9696961a 0 0 20px 0;
}

.main-header {
    flex-direction: row;
    min-height: 80px;
}

.main-header .logo .logo-img {
    width: 120px;
}

.main-header .logo .burger {
    display: none;
}

.nav-list {
    display: flex;
    align-items: center;
}

.nav-list > ul {
    padding: 0;
    list-style-type: none;
}

.nav-list > ul > li {
    padding-left: 1.5em;
    color: #282e2e;
}

.nav-list > ul a {
    text-decoration: none;
}

.nav-list ul a.active {
    color: blue;
}

.get-quote .quote {
    height: 45px;
    width: 170px;
}

.get-quote .chat-icon {
    height: 35px;
    width: 35px;
}

.drop-menu-head {
    display: none;
}

#toggle, #toggleDrop {
    display: none;
}

@media (min-width: 400px) {
    .main-header .logo .logo-img {
        width: 150px;
    }
}

@media (min-width: 992px) {
    .main-header .logo .logo-img {
        width: 180px;
    }
}

@media (min-width: 1025px) {

    #toggleDrop:checked + .drop-menu-list {
        /*.drop-menu:hover .drop-menu-list {*/
        display: block;
    }
    label {
        transition: 0.3s;
        cursor: pointer;
    }
    label:hover {
        color: var(--color-primary);
    }
}

@media all and (max-width: 1024px) {

    #toggleDrop:checked + .drop-menu-list {
        transition: 0.6s;
        transform: translate3d(0, 0, 0);
    }

    .nav-list {
        transition: 0.6s;
        transform: translate3d(100%, 0, 0);
        display: unset;
    }

    .nav-list > ul {
        flex-direction: column;
        /*align-items: center;*/
        overflow: scroll;
        height: calc(100vh - 180px);
        margin-top: 30px;
    }

    .nav-list > ul > li {
        padding: 0;
        margin: 0 1rem;
        border-bottom: 1px solid #c2c2c2ad;
        text-align: center;
        font-size: 1.2rem;
    }

    .nav-list > ul > li > * {
        padding: 1rem 0;
        display: block;
    }

    .get-quote {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%, 0px);
        z-index: -1;
    }

    .get-quote .chat-icon {
        display: none;
    }

    .main-header {
        flex-direction: column;
        justify-content: space-around;
    }

    .main-header .logo {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }

    .main-header .logo .burger {
        display: inline-block;
        height: 20px;
        width: 20px;
    }

    .nav-list {
        position: absolute;
        top: 80px;
        height: calc(100vh - 80px);
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
        z-index: 9999;
        text-align: center;
        margin-top: 0;
    }

    .hamburger {
        z-index: 99999;
    }

    .hamburger div {
        position: relative;
        width: 2em;
        height: 4px;
        border-radius: 3px;
        background-color: var(--color-primary);
        margin-top: 6px;
        transition: all 0.3s ease-in-out;
    }

    .hamburger div:first-child {
        margin-top: 0;
    }

    #toggle:checked + .logo .hamburger .top-bun {
        transform: rotate(-45deg);
        margin-top: 5px;
    }

    #toggle:checked + .logo .hamburger .bottom-bun {
        opacity: 0;
        transform: rotate(45deg);
    }

    #toggle:checked + .logo .hamburger .meat {
        transform: rotate(45deg);
        margin-top: -4px;
    }

    #toggle:checked + .logo + .nav-list {
        transition: 0.6s;
        transform: translate3d(0, 0, 0);
    }

    #toggle:checked + .nav-list {
        transition: 0.6s;
        transform: translate3d(0, 0, 0);
    }

}
</style>
