<template>
    <div class="bg-2" id="top">
        <!--        <lego-nav-bar  drop-down-animation="bounce" :items="menu"/>-->
        <MainHeader ref="header" v-click-outside="clicked"></MainHeader>
        <div class="">
            <transition name="fade" mode="out-in">
                <router-view>

                </router-view>
            </transition>
        </div>

        <Footer/>
        <!--        <div class="bg-dark p-3">-->
        <!--            Powered by Xeoscript Technologies-->
        <!--        </div>-->
    </div>

</template>

<script>
// import LegoNavBar from 'lego-framework/src/components/NavBar';
import getMenu from '../data/menu';
import MainHeader from '../views/components/MainHeader';
import Footer from '../views/components/Footer';

export default {
    components: {
        Footer,
        MainHeader
        // LegoNavBar
    },
    data () {
        return {
            menu: getMenu(),
            value: ''
        };
    },
    metaInfo: {
        titleTemplate: '%s - Medico Pharm',
        htmlAttrs: {
            lang: 'en',
            amp: true
        }
    },
    methods: {
        clicked () {
            this.$refs.header.clicked();
        }

    },
    name: 'MainLayout'
};
</script>

<style>
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}
</style>
